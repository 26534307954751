// signature should be in order: arr, idx, el, for readability
export const pushToArray = (arr: any[], el: any) => {
  if (!arr) {
    return [el];
  }
  return [...arr, el];
};
export const insertAtIndex = (arr: any[], idx: number, el: any) => {
  return [...arr.slice(0, idx), el, ...arr.slice(idx)];
};
export const removeAtIndex = (arr: any[], idx: number) => {
  return [...arr.slice(0, idx), ...arr.slice(idx + 1)];
};
export const replaceAtIndex = (arr: any[], idx: number, el: any) => {
  //todo: check array index bounds, numbers
  return [...arr.slice(0, idx), el, ...arr.slice(idx + 1)];
};
export const swapElements = (arr: any[], idx1: number, idx2: number) => {
  const maxLength = arr.length - 1;
  if (idx1 < 0 || idx2 < 0 || idx1 > maxLength || idx2 > maxLength) {
    return [...arr];
  }
  const [smaller, larger] = [idx1, idx2].sort(); // todo: check for numbers
  const el1 = arr[smaller];
  const el2 = arr[larger];
  return [...arr.slice(0, smaller), el2, ...arr.slice(smaller + 1, larger), el1, ...arr.slice(larger + 1)];
};
export const moveToIndex = (arr: any[], from: number, to: number) => {
  const maxLength = arr.length - 1;
  if (from < 0 || to < 0 || from > maxLength || to > maxLength) {
    throw new Error('Index out of bounds');
  }
  const element = arr[from];
  const newArr = [...arr.slice(0, from), ...arr.slice(from + 1)];
  return [...newArr.slice(0, to), element, ...newArr.slice(to)];
};
