const resizeElement = (el: HTMLElement) => {
  el.style.height = `auto`;
  el.style.height = `${el.clientHeight - el.offsetHeight + el.scrollHeight}px`;
};

export default {
  updated: resizeElement,
  mounted: resizeElement,
  created(el: HTMLElement) {
    el.classList.add('skillbox-auto-grow');
    el.addEventListener('input', () => {
      resizeElement(el);
    });
  },
  unmounted(el: HTMLElement) {
    el.classList.remove('skillbox-auto-grow');
    el.removeEventListener('input', () => {
      resizeElement(el);
    });
  },
};
