import { ONBOARDING_STEP_1, ONBOARDING_STEP_2, ONBOARDING_STEP_3 } from '@/router/onboarding.names';

const onboarding = () => import('@/pages/onboarding.vue');
const onboardingStart = () => import('@/pages/onboarding/start.vue');
const onboardingStep1 = () => import(/* webpackChunkName: "onboarding" */ '@/pages/onboarding/step1.vue');
const onboardingStep2 = () => import(/* webpackChunkName: "onboarding" */ '@/pages/onboarding/step2.vue');
const onboardingStep3 = () => import(/* webpackChunkName: "onboarding" */ '@/pages/onboarding/step3.vue');

export default [
  {
    path: '/onboarding',
    component: onboarding,
    children: [
      {
        path: '',
        component: onboardingStart,
        name: 'onboarding-start',
        meta: {
          layout: 'split',
          next: ONBOARDING_STEP_1,
          matomoUrl: '/onboarding/start',
        },
      },
      {
        path: 'learning',
        component: onboardingStep1,
        name: ONBOARDING_STEP_1,
        meta: {
          layout: 'split',
          next: ONBOARDING_STEP_2,
          illustration: 'contents',
          matomoUrl: '/onboarding/learning',
        },
      },
      {
        path: 'collaboration',
        component: onboardingStep2,
        name: ONBOARDING_STEP_2,
        meta: {
          layout: 'split',
          next: ONBOARDING_STEP_3,
          illustration: 'rooms',
          matomoUrl: '/onboarding/collaboration',
        },
      },
      {
        path: 'portfolio',
        component: onboardingStep3,
        name: ONBOARDING_STEP_3,
        meta: {
          layout: 'split',
          next: 'home',
          illustration: 'portfolio',
          matomoUrl: '/onboarding/portfolio',
        },
      },
    ],
  },
];
