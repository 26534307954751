<template>
  <div class="content-element-actions">
    <button
      class="icon-button"
      @click.stop="toggle(true)"
    >
      <ellipses class="icon-button__icon" />
    </button>
    <widget-popover
      class="content-element-actions__popover"
      :no-padding="true"
      v-if="show"
      @hide-me="toggle(false)"
    >
      <section class="content-element-actions__section">
        <button-with-icon-and-text
          class="content-element-actions__button"
          :large="true"
          icon="arrow-thin-top"
          text="Ganz nach oben verschieben"
          :disabled="!actions.up"
          v-if="actions.extended"
          @click="emitAndClose('move-top')"
        />
        <button-with-icon-and-text
          class="content-element-actions__button"
          :large="true"
          :disabled="!actions.up"
          icon="arrow-thin-up"
          text="Nach oben verschieben"
          @click="emitAndClose('move-up')"
        />
        <button-with-icon-and-text
          class="content-element-actions__button"
          :large="true"
          :disabled="!actions.down"
          icon="arrow-thin-down"
          text="Nach unten verschieben"
          @click="emitAndClose('move-down')"
        />
        <button-with-icon-and-text
          icon="arrow-thin-bottom"
          class="content-element-actions__button"
          :large="true"
          :disabled="!actions.down"
          text="Ganz nach unten verschieben"
          v-if="actions.extended"
          @click="emitAndClose('move-bottom')"
        />
      </section>

      <section class="content-element-actions__section">
        <button-with-icon-and-text
          class="content-element-actions__button"
          :large="true"
          icon="trash-icon"
          text="Löschen"
          @click="emitAndClose('remove')"
        />
      </section>
    </widget-popover>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import WidgetPopover from '@/components/ui/WidgetPopover.vue';
import Ellipses from '@/components/icons/Ellipses.vue';
import ButtonWithIconAndText from '@/components/ui/ButtonWithIconAndText.vue';
import { ActionOptions } from '@/@types';

interface Data {
  show: boolean;
}

export default defineComponent({
  props: {
    actions: {
      type: Object as () => ActionOptions,
    },
  },
  components: { ButtonWithIconAndText, Ellipses, WidgetPopover },

  data: (): Data => ({
    show: false,
  }),

  methods: {
    toggle(show: boolean) {
      this.show = show;
    },
    close() {
      this.show = false;
    },
    up() {
      this.$emit('move-up');
      this.close();
    },
    down() {
      this.$emit('move-down');
      this.close();
    },
    top() {
      this.$emit('move-top');
      this.close();
    },
    bottom() {
      this.$emit('move-bottom');
      this.close();
    },
    emitAndClose(event: string) {
      this.$emit(event);
      this.close();
    },
  },
});
</script>

<style scoped lang="scss">
@import 'styles/helpers';

.content-element-actions {
  position: relative;

  &__popover {
    white-space: nowrap;
    top: 100%;
    transform: translateY($small-spacing);
  }

  &__section {
    border-bottom: 1px solid $color-silver-dark;
    padding: $medium-spacing $small-spacing;

    &:last-child {
      border-bottom: 0;
    }
  }

  &__button {
    margin-bottom: $medium-spacing;
  }
}
</style>
