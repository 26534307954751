<template>
  <div
    :class="{ 'widget-popover--mobile': mobile }"
    class="widget-popover"
    v-click-outside="hidePopover"
  >
    <ul :class="['widget-popover__links popover-links', { 'popover-links--no-padding': noPadding }]">
      <slot />
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    mobile: {
      type: Boolean,
      default: false,
    },
    noPadding: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    hidePopover() {
      this.$emit('hide-me');
    },
  },
};
</script>
