import NEWS_TEASER_QUERY from '@/graphql/gql/queries/newsTeasersQuery.gql';

export default {
  data() {
    return {
      newsTeasers: [],
    };
  },
  apollo: {
    newsTeasers: {
      query: NEWS_TEASER_QUERY,
    },
  },
};
