import {
  currentFilterVar,
  helloEmailVar,
  scrollToElementVar,
  showNavigationSidebarVar,
  showProfileSidebarVar,
  languageVar,
} from '@/graphql/cache';

// todo: this probably can all be done with the apollo vars in the calling components, but that might need a rewrite of
// the parts where the mutation is called and then read. But maybe it works by itself already
export const resolvers = {
  Mutation: {
    scrollTo: (_, { scrollTo }) => {
      scrollToElementVar(scrollTo);
      return {
        scrollTo: scrollToElementVar(),
      };
    },
    helloEmail: (_, { email }) => {
      helloEmailVar(email);
      return {
        email: helloEmailVar(),
      };
    },
    setInstrumentFilter: (_, { filter }) => {
      currentFilterVar(filter);
      return {
        currentFilter: currentFilterVar(),
      };
    },
    toggleSidebar: (_, { sidebar: { profile, navigation } }) => {
      if (typeof profile !== 'undefined') {
        showProfileSidebarVar(profile);
      }
      if (typeof navigation !== 'undefined') {
        showNavigationSidebarVar(navigation);
      }
      return {
        navigation: showNavigationSidebarVar(),
        profile: showProfileSidebarVar(),
      };
    },
    setLanguage: (_, { language }) => {
      languageVar(language);
      return {
        language: languageVar(),
      };
    },
    // todo: does this still work?
    deleteModuleNodes: (_, _query, { cache }) => {
      Object.keys(cache.data.data)
        .filter((prop) => prop.indexOf('ModuleNode:') === 0)
        .map((moduleName) => cache.data.delete(moduleName)); // v3 uses .evict{id, fieldname}
      return { success: true };
    },
  },
};
