import type { FlavorValues } from '@/helpers/types';

export const defaultFlavorValues: FlavorValues = {
  appFlavor: 'skillbox',
  appLogo: 'https://skillbox-files-prod.s3.eu-west-1.amazonaws.com/mySkillbox-logo-2024.png',
  baseUrl: 'myskillbox.ch',
  licenseUrl: 'https://myskillbox.ch/angebot',

  // mySkillbox texts
  textAppName: 'mySkillbox',
  textTopic: 'Thema',
  textTopics: 'Themen',
  textModule: 'Modul',
  textModules: 'Module',
  textInstrument: 'Instrument',
  textInstruments: 'Instrumente',
  textInstrumentFilterShowAll: 'Alle anzeigen',

  // mySkillbox flags
  showFooter: true,
  showObjectivesTitle: true,
  showInstrumentFilterSidebar: true,
  showInstrumentSubCategories: true,
  showPortfolio: true,
  showTime2Learn: false,
  showEHB: true,
  helloIllustration: 'HelloIllustration',
  showModuleFilter: false,
  showLanguageFilter: false,
  showTopicNavigationNumbering: true,
};

export const myKvValues: FlavorValues = {
  appFlavor: 'my-kv',
  appLogo: 'https://skillbox-my-kv-prod.s3-eu-west-1.amazonaws.com/mykv-logo-2024.png',
  baseUrl: 'mykv.ch',
  licenseUrl: 'https://hep.plus/mykv-angebot',

  // myKV texts
  textAppName: 'myKV',
  textTopic: 'HKB',
  textTopics: 'HKB',
  textModule: 'Modul',
  textModules: 'Module',
  textInstrument: 'Grundlagenwissen',
  textInstruments: 'Grundlagenwissen',
  textInstrumentFilterShowAll: 'Alles anzeigen',

  // myKV flags
  showFooter: true,
  showObjectivesTitle: false,
  showInstrumentFilterSidebar: true,
  showPortfolio: true,
  showInstrumentSubCategories: false,
  showEHB: false,
  helloIllustration: 'HelloMyKVIllustration',
  showModuleFilter: true,
  showLanguageFilter: true,
  showTopicNavigationNumbering: false,
};

export const dhaValues: FlavorValues = Object.assign({}, myKvValues, {
  appFlavor: 'my-dha',
  baseUrl: 'mydetailhandel.ch',
  licenseUrl: 'https://hep.plus/mydha-angebot',
  appLogo: 'https://skillbox-my-detailhandel-dha-prod.s3.eu-central-1.amazonaws.com/myDHA-logo-2024.png',
  textAppName: 'myDHA',
  helloIllustration: 'HelloMyDHAIllustration',
  showLanguageFilter: false,
  showTime2Learn: true,
});

export const dhfValues: FlavorValues = Object.assign({}, myKvValues, {
  appFlavor: 'my-dhf',
  baseUrl: 'mydetailhandel.ch',
  licenseUrl: 'https://hep.plus/mydhf-angebot',
  appLogo: 'https://skillbox-my-detailhandel-dhf-prod.s3.eu-central-1.amazonaws.com/myDHF-logo-2024.png',
  textAppName: 'myDHF',
  helloIllustration: 'HelloMyDHFIllustration',
  showLanguageFilter: false,
  showTime2Learn: true,

});
