<template>
  <svg
    viewBox="15 15 20 20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M33.855 23.7749H26.215V16.1349C26.215 15.4584 25.6666 14.9099 24.99 14.9099C24.3135 14.9099 23.765 15.4584 23.765 16.1349V23.7749H16.125C15.4485 23.7749 14.9 24.3234 14.9 24.9999C14.9 25.6765 15.4485 26.2249 16.125 26.2249H23.765V33.8649C23.765 34.5415 24.3135 35.0899 24.99 35.0899C25.6666 35.0899 26.215 34.5415 26.215 33.8649V26.2249H33.855C34.5316 26.2249 35.08 25.6765 35.08 24.9999C35.08 24.3234 34.5316 23.7749 33.855 23.7749Z"
    />
  </svg>
</template>
