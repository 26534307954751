<template>
  <div
    class="read-only-banner"
    data-cy="read-only-banner"
    v-if="isReadOnly"
  >
    <div class="read-only-banner__content">
      <p class="read-only-banner__text">{{ readOnlyText }} Sie können Inhalte lesen, aber nicht bearbeiten.</p>
      <div class="read-only-banner__buttons">
        <router-link
          :to="licenseActivationLink"
          data-cy="license-activation-link"
          class="read-only-banner__link button button--primary"
          v-if="isUserReadOnly"
        >
          Neuen Lizenzcode eingeben
        </router-link>
        <a
          target="_blank"
          href="https://myskillbox.ch/lesemodus"
          class="button button--secondary"
          >Mehr Informationen zum Lesemodus</a
        >
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { LICENSE_ACTIVATION } from '@/router/auth.names';
import { computed } from 'vue';
import { useReadonly } from '@/helpers/readonly';

const { isReadOnly, isUserReadOnly, isModuleReadOnly, isClassReadOnly } = useReadonly();

const readOnlyText = computed(() => {
  if (isUserReadOnly.value) {
    return 'Sie besitzen keine aktive Lizenz.';
  }
  if (isClassReadOnly.value) {
    return 'Sie sind in dieser Klasse nicht mehr aktiv.';
  }
  if (isModuleReadOnly.value) {
    return 'Sie besitzen für dieses Modul eine abgelaufene Lizenz.';
  }
  return 'Sie sind im Lesemodus.';
});

const licenseActivationLink = {
  name: LICENSE_ACTIVATION,
};
</script>

<style scoped lang="scss">
@import 'styles/helpers';

.read-only-banner {
  background-color: $color-brand-light;
  display: flex;
  justify-content: center;
  padding: $small-spacing 0;

  &__content {
    max-width: $screen-width;
  }

  &__text {
    padding: 0;
    @include regular-text;
    margin-bottom: $small-spacing;
  }

  &__buttons {
    display: flex;
    gap: $small-spacing;
  }

  &__link {
    @include default-link;
  }
}
</style>
