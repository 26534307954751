import { router } from '@/router';
import VueVimeoPlayer from 'vue-vimeo-player';
import { store } from '@/store';
import VueModal from '@/plugins/modal';
import VueRemoveEdges from '@/plugins/edges';
import { createApolloProvider } from '@vue/apollo-option';
import apolloClients from './apollo';
import flavorPlugin from '@/plugins/flavor';
import VueMatomo from 'vue-matomo';
import * as Sentry from '@sentry/vue';

const { publicApolloClient, privateApolloClient } = apolloClients;

const apolloProvider = createApolloProvider({
  clients: {
    publicClient: publicApolloClient,
  },
  defaultClient: privateApolloClient,
});
const registerPlugins = (app: any) => {
  if (import.meta.env.SENTRY_DSN) {
    Sentry.init({
      app,
      dsn: import.meta.env.SENTRY_DSN,
      environment: import.meta.env.SENTRY_ENVIRONMENT || 'localhost',
      integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
      tracesSampleRate: 0.1,
      replaysSessionSampleRate: 0.05,
      replaysOnErrorSampleRate: 1.0,
    });
  }
  app.use(store);
  app.use(VueModal);
  app.use(VueRemoveEdges);
  app.use(VueVimeoPlayer);
  app.use(apolloProvider);
  app.use(router);
  app.use(flavorPlugin);
  if (import.meta.env.MATOMO_HOST) {
    // MS-628: we use VueMatomo "only" to setup the Matomo tracker
    // we will not use any of the provided functions
    app.use(VueMatomo, {
      host: import.meta.env.MATOMO_HOST,
      siteId: import.meta.env.MATOMO_SITE_ID,
      enableHeartBeatTimer: true,
      // we don't want the default vue-matomo router behaviour
      router: null,
    });
  }
};

export default registerPlugins;
