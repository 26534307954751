import gql from 'graphql-tag';

export const typeDefs = gql`
  input SidebarInput {
    navigation: Boolean
    profile: Boolean
  }

  type ScrollPosition {
    scrollTo: String!
  }

  type HelloEmail {
    email: String!
  }

  type Sidebar {
    navigation: Boolean!
    profile: Boolean!
  }

  type InstrumentFilter {
    currentFilter: String!
  }

  extend type ModuleNode {
    inEditMode: Boolean!
  }

  extend type PrivateUserNode {
    language: String
  }

  type Mutation {
    scrollTo(scrollTo: String!): ScrollPosition
    helloEmail(email: String!): HelloEmail
    toggleSidebar(sidebar: SidebarInput!): Sidebar
    setLanguage(language: String!): PrivateUserNode
  }

  type Query {
    sidebar: Sidebar!
  }
`;
