<template>
  <div class="content-form-section">
    <h2 class="content-form-section__heading">
      <component
        class="content-form-section__icon"
        :is="icon"
      />
      <span
        class="content-form-section__title"
        data-cy="content-form-section-title"
        >{{ title }}</span
      >
    </h2>

    <content-element-actions
      class="content-form-section__actions"
      :actions="actions"
      v-if="actions"
      @remove="$emit('remove')"
      @move-top="$emit('top')"
      @move-up="$emit('up')"
      @move-down="$emit('down')"
      @move-bottom="$emit('bottom')"
    />
    <div class="content-form-section__content">
      <slot />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import formElementIcons from '@/components/ui/form-element-icons.js';
import ContentElementActions from '@/components/content-block-form/ContentElementActions.vue';
import { ActionOptions } from '@/@types';

export default defineComponent({
  props: {
    title: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    actions: {
      type: Object as () => ActionOptions,
      default: () => {},
    },
  },
  components: {
    ContentElementActions,
    ...formElementIcons,
  },
});
</script>

<style scoped lang="scss">
@import 'styles/helpers';

.content-form-section {
  @include default-box-shadow;
  border-radius: $default-border-radius;
  padding: $small-spacing $medium-spacing;
  margin-bottom: $medium-spacing;

  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-template-areas: 'h a' 'c c';
  align-items: center;
  grid-row-gap: $medium-spacing;

  &__heading {
    display: flex;
    align-items: center;
    grid-area: h;
    margin-bottom: 0;
  }

  &__actions {
    grid-area: a;
    justify-self: end;
  }

  &__title {
    @include heading-3;
    margin-bottom: 0;
  }

  &__icon {
    width: 28px;
    height: 28px;
    margin-right: $small-spacing;
  }

  &__content {
    grid-area: c;
  }
}
</style>
