const getRidOfEdges = (collection: any) => {
  // todo: get rid of this, this probably doesn't work too well with immutable objects
  // todo: don't use edges if they're not necessary
  if (typeof collection === 'object' && collection && !Array.isArray(collection)) {
    const newObj: Record<string, any> = {};
    for (const k in collection) {
      if (Object.prototype.hasOwnProperty.call(collection, k)) {
        if (k === 'edges') {
          return collection.edges.map((edge: any) => getRidOfEdges(edge.node));
        } else {
          newObj[k] = getRidOfEdges(collection[k]);
          if (newObj[k]) {
            // delete newObj[k]['__typename']
          }
        }
      }
    }
    return newObj;
  } else {
    return collection;
  }
};

const EdgesPlugin = {
  install(app: any) {
    app.config.globalProperties.$getRidOfEdges = getRidOfEdges;
  },
};

export default EdgesPlugin;
