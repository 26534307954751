<template>
  <label
    :for="id"
    data-cy="toggle-container"
    :class="['toggle', { 'toggle--bordered': bordered }]"
    @click.capture.prevent="$emit('input', !checked)"
  >
    <input
      :checked="checked"
      class="toggle__input"
      data-cy="toggle-checkbox"
      type="checkbox"
      :id="id"
    />
    <span
      data-cy="toggle-wrapper"
      class="toggle__toggle-wrapper"
    >
      <span
        class="toggle__toggle"
        data-cy="toggle-handle"
      />
    </span>
    <span
      class="toggle__label"
      data-cy="toggle-label"
      >{{ label }}</span
    >
  </label>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: '',
    },
    checked: {
      type: Boolean,
      default: false,
    },
    bordered: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['input'],

  data() {
    return {
      id: null,
    };
  },
  mounted() {
    this.id = this._uid;
  },
};
</script>

<style scoped lang="scss">
@import 'styles/helpers';

.toggle {
  display: flex;
  cursor: pointer;

  &--bordered {
    @include default-box-shadow;
    border-radius: $round-border-radius;
    border: 1px solid $color-silver;
    padding: $small-spacing $medium-spacing;
  }

  &__toggle-wrapper {
    background-color: $color-silver;
    width: 42px;
    height: 24px;
    border-radius: $round-border-radius;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 0 6px;
    margin-right: $small-spacing;
  }

  &__input {
    display: none;
  }

  &__input:checked + &__toggle-wrapper {
    background-color: $color-brand;
  }

  &__input:checked + &__toggle-wrapper &__toggle {
    margin-left: 14px;
  }

  &__toggle {
    width: 16px;
    height: 16px;
    border-radius: $round-border-radius;
    background-color: $color-white;
    margin-left: 0;
    transition: margin-left 0.5s;
  }

  &__label {
    @include regular-text;
  }
}
</style>
