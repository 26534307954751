<template>
  <div class="input-with-label">
    <label
      data-cy="input-with-label-label"
      class="input-with-label__label"
      >{{ label }}</label
    >
    <input
      :value="value"
      :placeholder="placeholder"
      data-cy="input-with-label-input"
      class="skillbox-input"
      @input="input"
    />
  </div>
</template>

<script setup lang="ts">
export interface Props {
  value?: string;
  label?: string;
  placeholder?: string;
}
withDefaults(defineProps<Props>(), {
  value: '',
  label: '',
  placeholder: ''
});

const emit = defineEmits(['input']);

const input = (event: Event) => {
  const value = (event.target as HTMLInputElement).value;
  console.log(value);
  emit('input', value);
}
</script>

<style scoped lang="scss">
@import 'styles/helpers';

.input-with-label {
  display: flex;
  flex-direction: column;
  &__label {
    @include regular-text;
    margin-bottom: $small-spacing;
  }
}
</style>
