<template>
  <div
    :class="{ 'no-scroll': showModal || showMobileNavigation }"
    class="app"
    id="app"
  >
    <read-only-banner />
    <scroll-up />
    <component
      :is="showModalDeprecated"
      v-if="showModalDeprecated"
    />
    <component
      :is="showModal"
      v-if="showModal"
    />
    <component :is="layout" />
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue';
import { mapGetters } from 'vuex';
import ScrollUp from '@/components/ScrollUp.vue';
import ReadOnlyBanner from '@/components/ReadOnlyBanner.vue';

import modals from '@/components/modals';

const NewContentBlockWizard = defineAsyncComponent(() =>
  import('@/components/content-block-form/NewContentBlockWizard.vue')
);
const EditContentBlockWizard = defineAsyncComponent(() =>
  import('@/components/content-block-form/EditContentBlockWizard.vue')
);
const EditRoomEntryWizard = defineAsyncComponent(() =>
  import('@/components/rooms/room-entries/EditRoomEntryWizard.vue')
);
const NewProjectEntryWizard = defineAsyncComponent(() => import('@/components/portfolio/NewProjectEntryWizard.vue'));
const EditProjectEntryWizard = defineAsyncComponent(() => import('@/components/portfolio/EditProjectEntryWizard.vue'));
const NewNoteWizard = defineAsyncComponent(() => import('@/components/notes/NewNoteWizard.vue'));
const EditNoteWizard = defineAsyncComponent(() => import('@/components/notes/EditNoteWizard.vue'));
const EditClassNameWizard = defineAsyncComponent(() => import('@/components/school-class/EditClassNameWizard.vue'));
const EditTeamNameWizard = defineAsyncComponent(() => import('@/components/profile/EditTeamNameWizard.vue'));
const EditSnapshotTitleWizard = defineAsyncComponent(() =>
  import('@/components/snapshots/EditSnapshotTitleWizard.vue')
);
const DefaultLayout = defineAsyncComponent(() => import('@/layouts/DefaultLayout.vue'));
const SimpleLayout = defineAsyncComponent(() => import('@/layouts/SimpleLayout.vue'));
const FullScreenLayout = defineAsyncComponent(() => import('@/layouts/FullScreenLayout.vue'));
const PublicLayout = defineAsyncComponent(() => import('@/layouts/PublicLayout.vue'));
const BlankLayout = defineAsyncComponent(() => import('@/layouts/BlankLayout.vue'));
const SplitLayout = defineAsyncComponent(() => import('@/layouts/SplitLayout.vue'));

export default {
  name: 'App',

  components: {
    ReadOnlyBanner,
    ScrollUp,
    DefaultLayout,
    SimpleLayout,
    FullScreenLayout,
    PublicLayout,
    BlankLayout,
    SplitLayout,
    NewContentBlockWizard,
    EditContentBlockWizard,
    EditRoomEntryWizard,
    NewProjectEntryWizard,
    EditProjectEntryWizard,
    NewNoteWizard,
    EditNoteWizard,
    EditClassNameWizard,
    EditTeamNameWizard,
    EditSnapshotTitleWizard,
    ...modals,
  },

  computed: {
    layout() {
      return (this.$route.meta.layout || 'default') + '-layout';
    },
    ...mapGetters({
      showModalDeprecated: 'showModal', // don't use this any more todo: remove this
      showMobileNavigation: 'showMobileNavigation',
    }),
    showModal() {
      return this.$modal.state.component;
    },
  },
};
</script>

<style lang="scss">
@import 'styles/helpers';

body {
  overflow-y: auto;
  overflow-x: hidden;
  height: 100vh;
}

.app {
  /*overflow-y: auto;*/
  min-height: 100vh;
  /*for IE10+*/
  display: flex;
  flex-direction: column;
}

.no-scroll {
  overflow-y: hidden;
}
</style>
