<template>
  <a
    class="add-content-link"
    data-cy="add-content-link"
    @click="click"
    ><plus-icon class="add-content-link__icon" /> <span class="add-content-link__text">Inhalt hinzufügen</span></a
  >
</template>

<script setup lang="ts">
import PlusIcon from '@/components/icons/PlusIcon.vue';

const emit = defineEmits(['click']);

const click = () => {
  emit('click');
}
</script>

<style scoped lang="scss">
@import 'styles/helpers';
$color: $color-silver-dark;
$icon-size: 14px;

.add-content-link {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;

  &__icon {
    width: $icon-size;
    height: $icon-size;
    margin-right: $small-spacing;
    fill: $color;
  }
  &__text {
    // custom style, because the view needs this
    @include large-link;
    color: $color;
  }
}
</style>
