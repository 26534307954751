// adapted from
// https://stackoverflow.com/questions/41791193/vuejs-reactive-binding-for-a-plugin-how-to/41801107#41801107
import { reactive, App } from 'vue';
import { Modal } from './modal.types';

class ModalStore {
  data: any;

  constructor() {
    this.data = reactive({
      component: '',
      payload: {},
    });
  }

  get state() {
    return this.data;
  }
}

declare module 'vue' {
  interface ComponentCustomProperties {
    $modal: Modal;
  }
}

const ModalPlugin = {
  install(app: App) {
    const store = new ModalStore();
    const reset = () => {
      store.state.component = '';
      store.state.payload = {};
    };

    const modal = {
      state: store.state,
      component: store.state.component,
      payload: store.state.payload,
      open(component: string, payload?: any) {
        store.state.payload = payload;
        store.state.component = component;
        return new Promise((resolve, reject) => {
          this._resolve = resolve;
          this._reject = reject;
        });
      },
      confirm(res: any) {
        reset();
        this._resolve(res);
      },
      cancel() {
        reset();
        this._reject();
      },
      _resolve: (_: any) => { },
      _reject: () => { },
    };

    app.config.globalProperties.$modal = modal;
    app.provide('modal', modal);
  },
};

export default ModalPlugin;
