<template>
  <component
    :is="component"
    v-bind="componentProps"
    @save="save"
    @back="goToModule"
  />
</template>

<script>
import CONTENT_BLOCK_QUERY from '@/graphql/gql/queries/contentBlockQuery.gql';
import ContentBlockForm from '@/components/content-block-form/ContentBlockForm.vue';

import EDIT_CONTENT_BLOCK_MUTATION from '@/graphql/gql/mutations/editContentBlock.gql';
import MODULE_DETAILS_QUERY from '@/graphql/gql/queries/modules/moduleDetailsQuery.gql';
import { setUserBlockType } from '@/helpers/content-block';
import { cleanUpContents, simplifyContents } from '@/components/content-block-form/helpers';

// import ContentBlockForm from '@/components/content-block-form/ContentBlockForm.vue';

export default {
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  components: { ContentBlockForm },

  data: () => ({
    contentBlock: null,
  }),

  computed: {
    // only display component once the prop is loaded
    component() {
      if (this.contentBlock) {
        return 'content-block-form';
      }
      return '';
    },
    componentProps() {
      return {
        title: 'Inhalte bearbeiten',
        contentBlock: this.contentBlock,
      };
    },
  },

  apollo: {
    contentBlock: {
      query: CONTENT_BLOCK_QUERY,
      variables() {
        return {
          id: this.id,
        };
      },
    },
  },

  methods: {
    save({ title, contents, isAssignment, id }) {
      const cleanedContents = cleanUpContents(contents);
      const simplifiedContents = simplifyContents(cleanedContents);
      const contentBlock = {
        title: title,
        contents: simplifiedContents,
        type: setUserBlockType(isAssignment),
      };
      const { slug } = this.$route.params;
      const input = {
        id,
        contentBlock,
      };
      this.$apollo
        .mutate({
          mutation: EDIT_CONTENT_BLOCK_MUTATION,
          variables: {
            input,
          },
          refetchQueries: [
            {
              query: MODULE_DETAILS_QUERY,
              variables: { slug },
            },
          ],
        })
        .then(this.goToModule);
    },
    goToModule() {
      // use the history, so the scroll position is preserved
      this.$router.go(-1);
    },
  },
};
</script>
