import { createApp } from 'vue';
import App from '@/App.vue';

import apolloClients from './apollo';
import { DefaultApolloClient } from '@vue/apollo-composable';
const { privateApolloClient } = apolloClients;

const setupApp = () => {
  const app = createApp(App);
  app.provide(DefaultApolloClient, privateApolloClient);
  return app;
};

export default setupApp;
