import {
  CHAPTER_DESCRIPTION_TYPE,
  CHAPTER_TITLE_TYPE,
  CONTENT_TYPE,
  OBJECTIVE_GROUP_TYPE,
  OBJECTIVE_TYPE,
} from '@/consts/types';

export type matomoUserRole = 'Student' | 'Teacher';

let matomoLastUrl = '';
let matomoLastUserId = '';
let matomoLastEventData = '';

declare global {
  interface Window {
    _paq: any[];
  }
}

window._paq = window._paq || [];

export function matomoTrackPageView(absolutePath: string = '/', title?: string) {
  // see https://developer.matomo.org/guides/spa-tracking for details
  const url = window.location.origin + absolutePath;

  if (matomoLastUrl !== url) {
    // do not track the same url twice
    // log.debug('trackMatomoPageView', { url, matomoLastUrl, title });
    window._paq.push(['setCustomUrl', url]);
    window._paq.push(['trackPageView', title]);
    matomoLastUrl = url;
  }
}

export function matomoTrackEvent(category: string, action: string, name?: string, value?: number) {
  const data = { category, action, name, value };
  const dataJSON = JSON.stringify(data);

  if (matomoLastEventData !== dataJSON) {
    // log.debug('matomoTrackEvent', { category, action, name, value });
    // @ts-ignore
    window._paq.push(['trackEvent', category, action, name, value]);
    matomoLastEventData = dataJSON;
  }
}

export function matomoTrackModuleVisibilityEvent(blockType: string, block: any, hidden: boolean) {
  let eventAction = '';
  let eventName = undefined;

  switch (blockType) {
    case CONTENT_TYPE:
      eventAction = hidden ? 'Inhalt ausgeblendet' : 'Inhalt eingeblendet';
      eventName = block.title;
      break;
    case OBJECTIVE_TYPE:
      eventAction = hidden ? 'Lernziel ausgeblendet' : 'Lernziel eingeblendet';
      eventName = block.text;
      break;
    case OBJECTIVE_GROUP_TYPE:
      eventAction = hidden ? 'Lernzielgruppe ausgeblendet' : 'Lernzielgruppe eingeblendet';
      eventName = block.displayTitle;
      break;
    case CHAPTER_TITLE_TYPE:
      eventAction = hidden ? 'Kapitelüberschrift ausgeblendet' : 'Kapitelüberschrift eingeblendet';
      eventName = block.title;
      break;
    case CHAPTER_DESCRIPTION_TYPE:
      eventAction = hidden ? 'Kapitelbeschreibung ausgeblendet' : 'Kapitelbeschreibung eingeblendet';
      eventName = block.title;
      break;
  }

  matomoTrackEvent('Modul Anpassen - Sichtbarkeit', eventAction, eventName);
}

export function matomoTrackUser(userId: string, role: matomoUserRole = 'Student') {
  // see https://developer.matomo.org/guides/tracking-javascript-guide#user-id for the process
  if (matomoLastUserId !== userId) {
    // log.debug('matomoTrackUser', { userId, matomoLastUserId, role });

    if (userId) {
      // @ts-ignore
      window._paq.push(['setUserId', userId]);
      // @ts-ignore
      window._paq.push(['setCustomDimension', 1, role]);
    } else {
      // @ts-ignore
      window._paq.push(['deleteCustomDimension', 1]);
      // User has just logged out, we reset the User ID
      // @ts-ignore
      window._paq.push(['resetUserId']);
      // we also force a new visit to be created for the pageviews after logout
      // @ts-ignore
      window._paq.push(['appendToTrackingUrl', 'new_visit=1']);
      // @ts-ignore
      window._paq.push(['trackPageView']);
      // we finally make sure to not again create a new visit afterwards (important for Single Page Applications)
      // @ts-ignore
      window._paq.push(['appendToTrackingUrl', '']);
    }

    matomoLastUserId = userId;
  }
}
