import { defineAsyncComponent } from 'vue';
const LinkIcon = defineAsyncComponent(() => import('@/components/icons/LinkIcon.vue'));
const VideoIcon = defineAsyncComponent(() => import('@/components/icons/VideoIcon.vue'));
const ImageIcon = defineAsyncComponent(() => import('@/components/icons/ImageIcon.vue'));
const TextIcon = defineAsyncComponent(() => import('@/components/icons/TextIcon.vue'));
const SpeechBubbleIcon = defineAsyncComponent(() => import('@/components/icons/SpeechBubbleIcon.vue'));
const DocumentIcon = defineAsyncComponent(() => import('@/components/icons/DocumentIcon.vue'));
const TitleIcon = defineAsyncComponent(() => import('@/components/icons/TitleIcon.vue'));
const DocumentWithLinesIcon = defineAsyncComponent(() => import('@/components/icons/DocumentWithLinesIcon.vue'));
const ArrowThinBottom = defineAsyncComponent(() => import('@/components/icons/ArrowThinBottom.vue'));
const ArrowThinDown = defineAsyncComponent(() => import('@/components/icons/ArrowThinDown.vue'));
const ArrowThinTop = defineAsyncComponent(() => import('@/components/icons/ArrowThinTop.vue'));
const ArrowThinUp = defineAsyncComponent(() => import('@/components/icons/ArrowThinUp.vue'));
const TrashIcon = defineAsyncComponent(() => import('@/components/icons/TrashIcon.vue'));
const TickCircleIcon = defineAsyncComponent(() => import('@/components/icons/TickCircleIcon.vue'));

/*
  for icons with a single word, leave the *-icon name, to prevent conflicts
  for others, both is fine: arrow-thin-up-icon or arrow-thin-up
*/
export default {
  LinkIcon,
  VideoIcon,
  ImageIcon,
  TextIcon,
  SpeechBubbleIcon,
  DocumentIcon,
  TitleIcon,
  DocumentWithLinesIcon,
  ArrowThinBottom,
  ArrowThinDown,
  ArrowThinTop,
  ArrowThinUp,
  TrashIcon,
  TickCircleIcon,
};
