import { graphql } from '@/__generated__';
import { useQuery } from '@vue/apollo-composable';
import { computed } from 'vue';
import { useRoute } from 'vue-router';

export function useReadonly() {
  const slug = computed(() => {
    return route.params.slug || '';
  });
  const hasSlug = computed(() => slug.value > '');
  const query = graphql(`
    query ReadOnlyQuery($slug: String = "", $hasSlug: Boolean = false) {
      me {
        readOnly
        selectedClass {
          readOnly
        }
      }
      module(slug: $slug) @include(if: $hasSlug) {
        readOnly
      }
    }
  `);

  const route = useRoute();
  const { result } = useQuery(query, () => ({ slug: slug.value, hasSlug: hasSlug.value }), {
    fetchPolicy: 'cache-only',
  });
  const me = computed(() => {
    const me = result.value?.me;
    return (
      me || {
        readOnly: false,
        selectedClass: {
          readOnly: false,
        },
      }
    );
  });
  const module = computed(() => {
    const module = result.value?.module;
    return (
      module || {
        readOnly: false,
      }
    );
  });
  const isUserReadOnly = computed(() => me.value.readOnly);
  const isClassReadOnly = computed(() => !!me.value.selectedClass?.readOnly);
  const isModuleReadOnly = computed(() => module.value.readOnly);
  const isReadOnly = computed(() => isUserReadOnly.value || isClassReadOnly.value || isModuleReadOnly.value);

  return {
    isUserReadOnly,
    isClassReadOnly,
    isModuleReadOnly,
    isReadOnly,
  };
}
