import {
  CREATE_CONTENT_BLOCK_AFTER_PAGE,
  CREATE_CONTENT_BLOCK_UNDER_PARENT_PAGE,
  EDIT_CONTENT_BLOCK_PAGE,
  MODULE_PAGE,
  MODULE_SETTINGS_PAGE,
  SNAPSHOT_DETAIL,
  SNAPSHOT_LIST,
  SUBMISSIONS_PAGE,
  VISIBILITY_PAGE,
} from '@/router/module.names';
import { LAYOUT_SIMPLE } from '@/router/core.constants';
import createContentBlock from '@/pages/createContentBlock.vue';
import editContentBlock from '@/pages/editContentBlock.vue';

const moduleBase = () => import('@/pages/module/module-base.vue');
const module = () => import('@/pages/module/module.vue');
const submissions = () => import('@/pages/submissions.vue');
const moduleVisibility = () => import('@/pages/module/moduleVisibility.vue');
const settingsPage = () => import('@/pages/module/moduleSettings.vue');
const snapshots = () => import('@/pages/snapshot/snapshots.vue');
const snapshot = () => import('@/pages/snapshot/snapshot.vue');
const contentBlockLocator = () => import('@/pages/contentBlockLocator.vue');

const contentBlockFormMeta = {
  // layout: LAYOUT_SIMPLE,
  hideFooter: true,
  hideHeader: true,
  showSubNavigation: true,
  pageClass: 'no-scroll',
};
const createContentBlockRouteFragment = {
  component: createContentBlock,
  meta: contentBlockFormMeta,
  props: true,
};

export default [
  {
    path: '/module/:slug',
    component: moduleBase,
    children: [
      {
        path: '',
        name: MODULE_PAGE,
        component: module,
        meta: { filter: true },
      },
      {
        path: 'submissions/:id',
        name: SUBMISSIONS_PAGE,
        component: submissions,
        meta: {
          filter: true,
          showSubNavigation: true,
        },
      },
      {
        path: 'settings',
        name: MODULE_SETTINGS_PAGE,
        component: settingsPage,
        meta: {
          showSubNavigation: true,
        },
      },
      {
        path: 'visibility',
        name: VISIBILITY_PAGE,
        component: moduleVisibility,
        meta: {
          layout: LAYOUT_SIMPLE,
          hideNavigation: true,
        },
      },
      {
        path: 'snapshots',
        component: snapshots,
        name: SNAPSHOT_LIST,
        meta: {
          showSubNavigation: true,
        },
      },
      {
        path: 'snapshot/:id',
        component: snapshot,
        name: SNAPSHOT_DETAIL,
        props: true,
        meta: {
          layout: LAYOUT_SIMPLE,
          hideNavigation: true,
          fullWidth: true,
        },
      },
      {
        path: 'edit/:id',
        meta: contentBlockFormMeta,
        props: true,
        component: editContentBlock,
        name: EDIT_CONTENT_BLOCK_PAGE,
      },
      {
        ...createContentBlockRouteFragment,
        path: 'add-after/:after',
        name: CREATE_CONTENT_BLOCK_AFTER_PAGE,
      },
      {
        ...createContentBlockRouteFragment,
        path: 'add-under-parent/:parent',
        name: CREATE_CONTENT_BLOCK_UNDER_PARENT_PAGE,
      },
    ],
  },
  {
    path: '/content/:id',
    props: true,
    component: contentBlockLocator,
  },
];
