<template>
  <content-block-form
    title="Inhaltsblock erfassen"
    :content-block="contentBlock"
    @back="goToModule"
    @save="save"
  />
</template>

<script>
import { defineComponent } from 'vue';

import ContentBlockForm from '@/components/content-block-form/ContentBlockForm.vue';
import { setUserBlockType } from '@/helpers/content-block';
import NEW_CONTENT_BLOCK_MUTATION from '@/graphql/gql/mutations/addContentBlock.gql';
import MODULE_DETAILS_QUERY from '@/graphql/gql/queries/modules/moduleDetailsQuery.gql';
import { cleanUpContents } from '@/components/content-block-form/helpers';

export default defineComponent({
  props: {
    parent: {
      type: String,
      default: '',
    },
    after: {
      type: String,
      default: '',
    },
  },

  components: {
    ContentBlockForm,
  },

  data: () => ({
    contentBlock: {
      title: '',
      isAssignment: false,
      contents: [],
    },
  }),

  methods: {
    save({ title, contents, isAssignment }) {
      let cleanedContents = cleanUpContents(contents);
      const contentBlock = {
        title: title,
        contents: cleanedContents,
        type: setUserBlockType(isAssignment),
      };
      let input;
      const { parent, after, slug } = this.$route.params;
      if (after) {
        input = {
          contentBlock,
          after,
        };
      } else {
        input = {
          contentBlock,
          parent,
        };
      }
      this.$apollo
        .mutate({
          mutation: NEW_CONTENT_BLOCK_MUTATION,
          variables: {
            input,
          },
          refetchQueries: [
            {
              query: MODULE_DETAILS_QUERY,
              variables: {
                slug,
              },
            },
          ],
        })
        .then(this.goToModule);
    },
    goToModule() {
      // use the history, so the scroll position is preserved
      this.$router.go(-1);
    },
  },
});
</script>
