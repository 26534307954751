<template>
  <div class="start-page">
    <div class="start-page__content">
      <div
        class="start-page__modules start-sections"
        data-cy="start-modules-list"
      >
        <h2
          class="start-page__heading"
          data-cy="start-page-heading"
        >
          Letzte {{ $flavor.textModules }}
        </h2>
        <h3
          class="start-page__no-modules"
          data-cy="no-modules-yet"
          v-if="!me.recentModules.length"
        >
          Sie haben sich noch kein {{ $flavor.textModule }} angeschaut. Legen Sie jetzt los!
        </h3>
        <div class="start-page__modules-list">
          <module-teaser
            :meta-title="module.metaTitle"
            :title="module.title"
            :hero-image="module.heroImage"
            :slug="module.slug"
            data-cy="start-module-teaser"
            v-for="(module, index) in me.recentModules"
            :key="index"
          />
        </div>
        <router-link
          :to="topicRoute"
          class="button"
        >
          Alle {{ $flavor.textModules }} anzeigen
        </router-link>
      </div>
      <div
        class="start-page__news news"
        data-cy="news-teasers"
        v-if="!me.readOnly"
      >
        <h2 class="start-page__heading">News</h2>
        <div class="news__list">
          <news-teaser
            :teaser="teaser"
            v-for="teaser in teasers"
            :key="teaser.id"
          />
        </div>

        <router-link
          :to="{ name: 'news' }"
          class="button"
        >
          Alle News anzeigen
        </router-link>
      </div>

      <div class="start-page__onboarding">
        <h2 class="start-page__heading">Kennen Sie schon alle Bereiche von {{ $flavor.textAppName }}?</h2>
        <p class="start-page__paragraph">Schauen Sie sich jetzt die Einführung zu {{ $flavor.textAppName }} an.</p>
        <router-link
          :to="{ name: 'onboarding-start' }"
          class="button button--primary"
        >
          Los geht's
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
import NewsTeaser from '@/components/news/NewsTeaser.vue';
import ModuleTeaser from '@/components/modules/ModuleTeaser.vue';

import meQuery from '@/mixins/me';
import news from '@/mixins/news';

export default {
  mixins: [meQuery, news],

  components: {
    NewsTeaser,
    ModuleTeaser,
  },

  computed: {
    teasers() {
      return this.newsTeasers.slice(0, 2);
    },
  },
};
</script>

<style scoped lang="scss">
@import 'styles/helpers';

.start-page {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @supports (display: grid) {
    display: grid;
    justify-content: center;
  }
  grid-template-rows: auto 1fr auto;
  min-height: 100vh;
  width: 100vw;
  box-sizing: border-box;

  &__title {
    color: $color-brand;
    text-align: center;
    border-bottom: 1px solid $color-silver-light;
    padding-bottom: 30px;
    margin-left: 30px;
    margin-right: 30px;
    margin-bottom: 60px;
  }

  &__heading {
    width: 100%;
    @include meta-title;
  }

  &__paragraph {
    @include regular-text;
    margin-bottom: $large-spacing;
  }

  &__content {
    padding-top: 2 * $large-spacing;
    box-sizing: border-box;
    max-width: 100%;
    @include desktop {
      max-width: $screen-width;
      margin: 0 auto;
    }
  }

  &__no-modules {
    @include heading-3;
    margin-bottom: 0;
  }

  &__modules {
    margin-bottom: $section-spacing;
  }

  &__modules-list {
    display: -ms-grid;
    -ms-grid-column-align: center;
    -ms-grid-columns: 1fr 1fr 1fr;
    width: 100%;
    margin-bottom: $large-spacing;

    @supports (display: grid) {
      display: grid;
    }
    @include desktop {
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: max-content;
    }

    grid-row-gap: 15px;
    grid-column-gap: 50px;
    justify-items: start;
    align-items: center;

    /*
       * For IE10+
       */
    & > :nth-child(1) {
      -ms-grid-row: 1;
      -ms-grid-column: 1;
    }

    & > :nth-child(2) {
      -ms-grid-row: 1;
      -ms-grid-column: 2;
    }

    & > :nth-child(3) {
      -ms-grid-row: 1;
      -ms-grid-column: 3;
    }
  }

  &__news {
    margin-bottom: $section-spacing;
  }
}

.news {
  padding-bottom: $large-spacing;

  &__list {
    display: flex;

    grid-row-gap: $large-spacing;
    grid-column-gap: $large-spacing;

    margin-bottom: $large-spacing;

    @supports (display: grid) {
      display: grid;
      grid-template-columns: 1fr;
    }

    @include desktop {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  /*
     * For IE10+
     */
  & > :nth-child(1) {
    -ms-grid-row: 1;
    -ms-grid-column: 1;
  }

  & > :nth-child(2) {
    -ms-grid-row: 1;
    -ms-grid-column: 2;
  }

  & > :nth-child(3) {
    -ms-grid-row: 1;
    -ms-grid-column: 3;
  }

  & > :nth-child(4) {
    -ms-grid-row: 1;
    -ms-grid-column: 4;
  }

  @include desktop {
    /*padding-left: 120px;*/
    /*padding-right: 120px;*/
  }

  &__title {
    font-family: $sans-serif-font-family;
    font-weight: $font-weight-bold;
    text-transform: uppercase;
    font-size: 2.1875rem;
    padding-bottom: 24px;
    text-align: center;
    color: inherit;
    margin-bottom: 0;
  }

  &__more {
    color: $color-white;
    font-family: $sans-serif-font-family;
    line-height: $default-line-height;
    padding-left: $medium-spacing;
    font-weight: $font-weight-bold;
    text-align: center;

    @include desktop {
      text-align: left;
      border-left: 1px solid $color-silver-light;
    }
  }
}
</style>
