<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
  >
    <circle
      cx="18.75"
      cy="50"
      r="5"
    />
    <circle
      cx="50"
      cy="50"
      r="5"
    />
    <circle
      cx="81.25"
      cy="50"
      r="5"
    />
  </svg>
</template>
