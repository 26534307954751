import { ADDITIONAL_LICENSE_ACTIVATION, LICENSE_ACTIVATION } from '@/router/auth.names';
import { LAYOUT_SIMPLE } from './core.constants';

const hello = () => import('@/pages/hello.vue');
const betaLogin = () => import('@/pages/beta-login.vue');
const loginError = () => import('@/pages/login-error.vue');
const licenseActivation = () => import('@/pages/license-activation.vue');
const additionalLicenseActivation = () => import('@/pages/additional-license-activation.vue');

export default [
  {
    path: '/hello',
    name: 'hello',
    component: hello,
    meta: {
      layout: 'split',
      public: true,
      illustration: 'hello',
      illustrationAlign: 'top',
      matomoUrl: '/auth/hello',
    },
  },
  {
    path: '/beta-login',
    name: 'betaLogin',
    component: betaLogin,
    meta: {
      layout: 'public',
      public: true,
      matomoUrl: '/auth/beta-login',
    },
  },
  {
    path: '/verify-email',
    component: loginError,
    name: 'verifyEmail',
    props: {
      title: 'Bitte schauen Sie in Ihr Postfach',
      errorMessage:
        'Ihre E-Mail-Adresse ist noch nicht verifiziert. Wir haben eine E-Mail mit einem Aktivierungslink an Sie verschickt.',
      alternateLink: 'https://www.hep-verlag.ch/',
      alternateLinkMessage: 'hep Verlag Webseite besuchen',
      extraMessages: [
        'Haben Sie keine E-Mail erhalten?',
        'Sie haben die Möglichkeit die E-Mail mit dem Aktivierungslink erneut anzufordern.\n' +
          'Besuchen Sie dafür die Webseite des hep Verlags, loggen Sie sich ein und beachten Sie den roten Hinweis.',
      ],
    },
    meta: {
      public: true,
      layout: 'public',
      matomoUrl: '/auth/verify-email',
    },
  },
  {
    path: '/unknown-auth-error',
    component: loginError,
    name: 'unknownAuthError',
    props: {
      title: 'Es ist ein Fehler aufgetreten',
      errorMessage:
        'Es tut uns leid, dass diese Seite im Moment nicht wie erwartet funktioniert. Bitte versuchen Sie es später nochmals.',
    },
    meta: {
      public: true,
      layout: 'public',
      matomoUrl: '/auth/unknown-auth-error',
    },
  },
  {
    path: '/license-activation',
    component: licenseActivation,
    name: LICENSE_ACTIVATION,
    meta: {
      layout: 'public',
      matomoUrl: '/auth/license-activation',
    },
  },
  {
    path: '/additional-license-activation',
    component: additionalLicenseActivation,
    name: ADDITIONAL_LICENSE_ACTIVATION,
    matomoUrl: '/auth/additional-license-activation',
    meta: { layout: LAYOUT_SIMPLE },
  },
];
