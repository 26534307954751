// import log from 'loglevel';
import type { FlavorValues } from '@/helpers/types';
import { defaultFlavorValues, dhaValues, dhfValues, myKvValues } from '@/helpers/app-flavor.constants';

let flavorValues: FlavorValues;

switch (import.meta.env.VUE_APP_FLAVOR) {
  case 'my-kv':
    flavorValues = myKvValues;
    break;
  case 'my-dha':
    flavorValues = dhaValues;
    break;
  case 'my-dhf':
    flavorValues = dhfValues;
    break;
  default:
    flavorValues = defaultFlavorValues;
}

// log.debug('flavorValues', flavorValues);

export default flavorValues;
