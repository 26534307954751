import { defineAsyncComponent } from 'vue';
const Modal = defineAsyncComponent(() => import('@/components/Modal.vue'));
const FullscreenImage = defineAsyncComponent(() => import('@/components/FullscreenImage.vue'));
const FullscreenInfographic = defineAsyncComponent(() => import('@/components/FullscreenInfographic.vue'));
const FullscreenVideo = defineAsyncComponent(() => import('@/components/FullscreenVideo.vue'));
const DeactivatePerson = defineAsyncComponent(() => import('@/components/profile/DeactivatePerson.vue'));
const SnapshotCreated = defineAsyncComponent(() => import('@/components/modules/SnapshotCreated.vue'));
const ChangeVisibility = defineAsyncComponent(() => import('@/components/rooms/ChangeVisibility.vue'));
const Confirm = defineAsyncComponent(() => import('@/components/modals/LegacyConfirm.vue'));

export default {
  Modal,
  FullscreenImage,
  FullscreenInfographic,
  FullscreenVideo,
  DeactivatePerson,
  SnapshotCreated,
  ChangeVisibility,
  Confirm,
};
