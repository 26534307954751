import {
  NEW_ROOM_PAGE,
  ROOMS_PAGE,
  ADD_ROOM_ENTRY_PAGE,
  ROOM_PAGE,
  UPDATE_ROOM_ENTRY_PAGE,
  ADD_MODULE_ROOM_ENTRY_PAGE,
  MODULE_ROOM_PAGE,
} from '@/router/room.names';

const rooms = () => import('@/pages/rooms/rooms.vue');
const newRoom = () => import('@/pages/rooms/newRoom.vue');
const editRoom = () => import('@/pages/rooms/editRoom.vue');
const room = () => import('@/pages/rooms/room.vue');
const newRoomEntry = () => import('@/pages/rooms/newRoomEntry.vue');
const editRoomEntry = () => import('@/pages/rooms/editRoomEntry.vue');
const moduleRoom = () => import('@/pages/module/moduleRoom.vue');

function matomoRoomWithSlugCallback(route) {
  return `/room/${route.params.slug}/`;
}

export default [
  { path: '/rooms', name: ROOMS_PAGE, component: rooms, meta: { filter: true, hideFooter: true, matomoUrl: '/room/' } },
  { path: '/new-room/', name: NEW_ROOM_PAGE, component: newRoom },
  { path: '/edit-room/:id', name: 'edit-room', component: editRoom, props: true },
  {
    path: '/room/:slug',
    name: ROOM_PAGE,
    component: room,
    props: true,
    meta: { matomoUrlCallback: matomoRoomWithSlugCallback },
  },
  {
    path: '/room/:slug/add',
    name: ADD_ROOM_ENTRY_PAGE,
    component: newRoomEntry,
    props: true,
    meta: { matomoUrlCallback: matomoRoomWithSlugCallback },
  },
  {
    path: '/room/:slug/edit/:entrySlug',
    name: UPDATE_ROOM_ENTRY_PAGE,
    component: editRoomEntry,
    props: true,
    meta: { matomoUrlCallback: matomoRoomWithSlugCallback },
  },
  {
    path: '/module-room/:slug',
    name: MODULE_ROOM_PAGE,
    component: moduleRoom,
    props: true,
    meta: { layout: 'fullScreen', matomoUrlCallback: matomoRoomWithSlugCallback },
  },
  {
    path: '/module-room/:slug/add',
    name: ADD_MODULE_ROOM_ENTRY_PAGE,
    component: newRoomEntry,
    props: (route) => ({ slug: route.params.slug, isModule: true }),
    meta: { matomoUrlCallback: matomoRoomWithSlugCallback },
  },
];
