<template>
  <div
    class="news-teaser"
    data-cy="news-teaser"
  >
    <a :href="teaser.newsArticleUrl">
      <img
        :src="teaser.imageUrl"
        class="news-teaser__image"
      />
      <h5 class="news-teaser__image-source">Quelle {{ teaser.imageSource }}</h5>

      <h4 class="news-teaser__title">{{ teaser.title }}</h4>
      <p class="news-teaser__description">{{ teaser.description }}</p>
      <p class="news-teaser__date">{{ teaser.displayDate }}</p>
    </a>
  </div>
</template>

<script>
export default {
  props: {
    teaser: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
};
</script>

<style scoped lang="scss">
@import 'styles/helpers';

.news-teaser {
  position: relative;

  &__image {
    display: block;
    max-width: 100%;
    height: auto;

    @include desktop {
      max-width: $news-width;
    }
  }

  &__image-source {
    line-height: 25px;
    @include tiny-text;
    margin-bottom: $medium-spacing;
    display: block;
  }

  &__title {
    margin-bottom: $small-spacing;
  }

  &__description {
    margin-bottom: $small-spacing;
  }

  &__date {
    @include regular-text;
    color: $color-silver-dark;
  }
}
</style>
