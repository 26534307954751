import apolloClients from './apollo';
const { publicApolloClient, privateApolloClient } = apolloClients;
import ME_QUERY from '@/graphql/gql/queries/meQuery.gql';
import { joiningClass, loginRequired, unauthorizedAccess } from '@/router/guards';
import { postLoginRedirectUrlKey, router } from '@/router';
import { JOIN_CLASS } from '@/router/me.names';
import { LICENSE_ACTIVATION } from '@/router/auth.names';

async function redirectUsersWithoutValidLicense() {
  const { data } = await privateApolloClient.query({
    query: ME_QUERY,
  });
  return data.me.expiryDate == null;
}

async function redirectStudentsWithoutClass() {
  const { data } = await privateApolloClient.query({
    query: ME_QUERY,
  });
  return data.me.schoolClasses.length === 0 && !data.me.isTeacher;
}

async function redirectUsersToOnboarding() {
  const { data } = await privateApolloClient.query({
    query: ME_QUERY,
  });
  return !data.me.onboardingVisited;
}

const setupRouteGuards = () => {
  router.beforeEach(async (to, _, next) => {
    // todo: make logger work outside vue app
    // const logger = inject('vuejs3-logger');
    // logger.$log.debug('navigation guard called', to, from);
    if (to.path === '/logout') {
      await publicApolloClient.resetStore();
      if (import.meta.env.LOGOUT_REDIRECT_URL) {
        location.replace(`https://sso.hep-verlag.ch/logout?return_to=${import.meta.env.LOGOUT_REDIRECT_URL}`);
        next(false);
        return;
      } else {
        next({ name: 'hello' });
        return;
      }
    }

    if (unauthorizedAccess(to)) {
      //logger.$log.debug('unauthorized', to);
      const postLoginRedirectionUrl = to.path;
      const redirectUrl = `/hello/`;

      if (window.localStorage) {
        localStorage.setItem(postLoginRedirectUrlKey, postLoginRedirectionUrl);
      }

      // logger.$log.debug('redirecting to hello', to);
      next(redirectUrl);
      return;
    }

    if (to.name && to.name !== LICENSE_ACTIVATION && loginRequired(to) && (await redirectUsersWithoutValidLicense())) {
      // logger.$log.debug('redirecting to licenseActivation', to, null);
      console.log('redirecting to licenseActivation', to, null);
      next({ name: LICENSE_ACTIVATION });
      return;
    }

    if (!joiningClass(to) && loginRequired(to) && (await redirectStudentsWithoutClass())) {
      //logger.$log.debug('redirecting to join-class', to);
      //logger.$log.debug('await redirectStudentsWithoutClass()', await redirectStudentsWithoutClass());
      next({ name: JOIN_CLASS });
      return;
    }

    if (
      to.name &&
      (to.name as string).indexOf('onboarding') === -1 &&
      !joiningClass(to) &&
      loginRequired(to) &&
      (await redirectUsersToOnboarding())
    ) {
      //logger.$log.debug('redirecting to onboarding-start', to);
      next({ name: 'onboarding-start' });
      return;
    }

    //logger.$log.debug('End of Guard reached', to);
    next();
  });
};

export default setupRouteGuards;
